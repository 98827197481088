.auth-form-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .auth-form-header {
    margin-bottom: 20px;
  }
  
  .auth-form-card {
    width: 350px !important;
  }
  
  .form-labels {
    text-align: left;
  }
  
  .auth-form-fields,
  .auth-form-buttons {
    margin-top: 20px !important;
  }
  
  .auth-form-buttons {
    width: 100%;
  }
  
  .google-login {
    margin-top: 40px;
  }

  .auth-form-wrapper {
    margin-top: 10px;
  }