.App {
  font-family: 'Roboto', sans-serif;;
  text-align: center;
  background-color: #ebebeb;
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #282c34 !important;
  color:  white;
  padding: 12px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  height: 5.5rem;
  width: 100%
}

.navbar-collapse {
  background-color: #282c34;
}

.navbar-toggler {
  background-color: white !important;
}

.btn-outline-primary {
  color: #282c34;
  border-color: #282c34;
}

.auth-form-wrapper {
  margin-top: 50px;
}

.btn-outline-primary:hover{
  background-color: #282c34;
  border-color: #282c34;
}

.btn-outline-danger {
  background-color: transparent;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
}

.btn-outline-danger:focus {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-outline-info {
  background-color: transparent;
}

.btn-outline-info:hover {
  background-color: #17a2b8;
}

.btn-outline-danger:focus {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.table td, .table th {
  vertical-align: middle;
}

h3 {
  text-align: center;
}

#storediv {
  text-align: center;
  bottom: 0;
  position: static;
  left: 0;
  width: 100%;
  height: 1rem;
  font-size: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
}

#storediv:last-child {
  height: 160%;
  text-align: center;
}

Footer {
  margin-top: 200px !important;
  text-align: center;
  bottom: 0;
  position: static;
  left: 0;
  width: 100%;
  height: 1rem;
  font-size: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
}

